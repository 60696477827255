.flex_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: var(--component-background);
}

.empty {
  flex: 1;
}
.warning_icon {
  flex: 4;
  text-align: center;
}
.msg {
  text-align: center;
  padding: 40px 0;
}
