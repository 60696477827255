.box {
  background-color: var(--background-color-light);
  border: 1px solid var(--border-color-split);
  border-radius: 3px;
  margin: 10px 0 32px 0;
  padding: 10px;
  width: 540px;
  height: 340px;
  overflow-y: auto;
  overflow-x: auto;

  :global {
    .ant-tree {
      background-color: var(--background-color-light);
    }
  }
}

.wrapperPadding {
  padding: 20px;
}
