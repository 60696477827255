.wrapper {
  background: initial;
  min-height: calc(100vh - 130px);
  // background: #29292c;
  padding: 0 !important;
}

.lampImage > :first-child {
  transition: 0.5s;
}
.lampImage:hover > :first-child {
  transform: scale(1.25);
}

.cardsWrapper {
  background: var(--component-background);
  padding: 18px;
  height: 100%;
}

.navCard {
  height: 100%;
  cursor: pointer;
  background: linear-gradient(270deg, #2d3744 0%, rgba(45, 55, 68, 0.1) 100%) !important;
  border-radius: 4px !important;
  border: 1px solid !important;
  border-image: linear-gradient(270deg, rgba(59, 194, 255, 0.5), rgba(9, 144, 181, 0)) 1 1 !important;
  &:hover {
    background: linear-gradient(90deg, rgba(3, 48, 69, 0.85) 0%, #034740 100%) !important;
    box-shadow: 0px 0px 15px 0px rgba(0, 173, 255, 0.31) !important;
    border-radius: 4px !important;
    border: 1px solid !important;
    border-image: linear-gradient(90deg, rgba(23, 166, 235, 0.55), rgba(59, 242, 247, 0.5)) 2 2 !important;
  }
  :global {
    .ant-card-body {
      padding: 16px;
    }
  }
}

.navButton {
  // color: rgba(255, 255, 255, 0.85) !important;
  width: 100% !important;
  min-width: 100px;
  padding: 0 8px;
  background: rgba(74, 144, 226, 0.15) !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5) !important;
  border: 1px solid rgba(0, 173, 255, 0.2) !important;
  border-radius: 0 !important;
  position: relative !important;
  &:hover {
    background: linear-gradient(267deg, rgba(11, 144, 1, 0.6) 0%, rgba(0, 110, 140, 0.56) 100%) !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.23) !important;
    border: 1px solid !important;
    border-image: linear-gradient(180deg, rgba(0, 214, 255, 1), rgba(0, 173, 255, 1)) 1 1 !important;
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.23);
    // transition: background-color 500ms linear;
  }
}

.slash {
  position: absolute;
  bottom: 2.6px;
  left: -3.8px;
  width: 12px;
  height: 1.2px;
  background-color: #0c3736;
  transform: rotate(45deg);
}

.triangle {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-bottom: 7px solid rgba(0, 214, 255, 1);
  border-right: 7px solid transparent;
}

.dot {
  position: absolute;
  width: 2px;
  height: 2px;
  // border-radius: 1px;
  background: #00adff;
  opacity: 0.5;
  &:hover {
    display: none !important;
  }
}

.navBanner {
  width: 100%;
  margin-bottom: 12px;
  :global {
    .ant-space-item:nth-of-type(2) {
      width: 100%;
    }
    .ant-space-item:nth-of-type(1) img {
      width: auto;
      height: auto;
      display: inline-block;
    }
  }
}
