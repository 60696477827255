$prefixCls: cc;
$border-color-base: var(--disabled-color);
$auto-sync-bg: var(--item-active-bg);
$text-color: var(--text-color);

.#{$prefixCls}-status-button {
  padding: 0 3px;
  border-radius: 2px;
  border: 1px solid $border-color-base;
  background: $auto-sync-bg;
  color: $text-color;
  user-select: none;
  cursor: pointer;
  height: 20px;
  font-size: 12px;
  &-checked {
    background: var(--mx-primary-color);
  }
  &-disabled {
    cursor: not-allowed;
  }
  &-icon {
    margin: 0 0 0 3px !important;
    color: $text-color;
  }
}
