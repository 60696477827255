$primary-color: var(--mx-primary-color);

.noStaffModal {
  text-align: center;
  .p {
    margin: 30px auto 30px auto;
    .icon {
      color: $primary-color;
      font-size: 16px;
      display: inline-block;
      margin-right: 10px;
    }
    .span {
      font-size: 16px;
    }
  }
}
.multiStaffList {
  padding: 30px 10px 30px 30px;
  position: relative;
  :global {
    .ant-row {
      width: 100%;
    }
    .ant-card-body {
      padding-bottom: 40px;
    }
  }
  .content {
    p:first-of-type {
      font-size: 16px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 5px;
    }
  }
  .img {
    width: 280px;
    height: 105px;
    object-fit: contain;
  }
  .cardFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid rgba(236, 236, 236, 0.16);
    height: 40px;
    line-height: 40px;
    text-align: right;
  }
}

.mod {
  .modHd {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 24px;

    .logo {
      height: 100%;
      img {
        width: auto;
        height: 100%;
      }
    }

    .divider {
      top: 0;
      height: 32px;
      margin: 0 10px 0 20px;
      border-left: 1px solid #d9d9d9;
    }

    .name {
      font-size: 16px;
      font-weight: 600;
      color: var(--mx-text-base-color);
    }
  }

  .modBd {
    ul {
      margin: 0;
      padding-left: 24px;
      li {
        margin-bottom: 6px;
      }
    }
  }
}
