%padding {
  padding: 0 20px;
}

.filterWrapper {
  border-bottom: 10px solid var(--body-background);
  padding-top: 10px;
  @extend %padding;
}

.content {
  .operationArea {
    margin-bottom: 10px;
  }
}

.form {
  width: 450px;
}
