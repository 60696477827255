$primary-color: var(--primary-color);
.operateContainer {
  margin-top: 20px;
}
.warning {
  color: var(--mx-warning-color);
}
.contactAdmin {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.wrapperPadding {
  padding: 20px;
}
