.iframeView {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  iframe {
    display: block; // 防止iframe底部留白
  }
  :global {
    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
  }
}
