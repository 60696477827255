@import '~@maxtropy/components/es/lib/theme/dark.scss';

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Microsoft YaHei', 'PingFang SC', '黑体', '宋体', sans-serif;
  min-width: 1366px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: color 111111s ease-out, background-color 111111s ease-out;
}
