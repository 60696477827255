$primary-color: var(--mx-primary-color);
.wrapper {
  background: transparent;
  padding: 0 !important;
}
.background {
  background-color: var(--mx-module-bg-color);
  padding: 20px;
  margin: 10px 0;
  .code {
    margin: 0;
  }
  .company {
    display: inline-block;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
  :global {
    .ant-tabs-ink-bar {
      background: $primary-color;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab:hover {
      color: $primary-color;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border: 0;
    }
    .ant-row {
      align-items: center;
    }
    .mx-rc-tree .ant-tree-switcher {
      width: 16px;
    }
    .ant-tree .ant-tree-node-content-wrapper {
      white-space: nowrap;
    }
    .ant-tree-list-holder {
      padding-bottom: 20px;
    }
    .ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
      height: 16px !important;
    }
    .ant-tree .ant-tree-switcher-leaf-line:after {
      width: 13.6px;
      height: 16px;
      left: 3px;
    }

    .editable-row:hover .editable-cell-value-wrap {
      border: 1px solid var(--mx-input-hover-border-color);
    }
  }
}

.titleFormatCode {
  color: var(--mx-text-secondary-color);
  font-size: 12px;
  margin-left: 10px;
}

.titleFormatIcon {
  margin-left: 50px;
  :global {
    .anticon {
      color: $primary-color;
    }
  }
}

.box {
  display: flex;
  align-items: flex-end;
}

.gray {
  color: var(--mx-text-desc-color);
  margin: 0 0 10px 0px;
  :global {
    .anticon {
      color: $primary-color;
      margin-right: 5px;
    }
  }
}

.restLogo {
  color: $primary-color;
  display: block;
  margin-top: -25px;
  cursor: pointer;
}

.red {
  color: var(--mx-error-color);
}

.hint {
  color: var(--mx-text-desc-color);
  margin: 5px 0 0 78px;
}

.icon {
  width: 24px;
  height: 24px;
  background: rgba(0, 159, 168, 0.15);
  border-radius: 20px;
  color: $primary-color;
  line-height: 28px;
  margin-right: 8px;
  padding-left: 5px;
}

.mark {
  color: $primary-color;
  margin: 0 8px;
}

.col {
  border-left: 1px solid var(--mx-light-border-color);
}
