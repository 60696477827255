.App {
  height: 100%;
}

::-webkit-scrollbar {
  border-radius: 0;
  background: var(--mx-scrollbar-bg);
  width: 8px;
  height: 8px;
}
//滚动条上滑块
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-bg);
}
::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-hover-bg);
}
// table 操作栏按钮
.action-buttons {
  .ant-btn-link {
    padding: 0;
    line-height: 30px;
    height: 30px;
    font-size: 12px;
  }
  .ant-btn {
    font-size: 12px;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 2px;
  }
}

// sticky table 横向滚动条
.ant-table-sticky-scroll {
  display: none;
}

.modal-form-content {
  padding: 32px 72px 8px 72px !important;
}

input:-internal-autofill-selected {
  background-clip: text;
  -webkit-text-fill-color: var(--mx-text-base-color);
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  background-color: transparent;
  -webkit-text-fill-color: var(--mx-text-base-color);
  transition: background-color 5000s ease-in-out 0s !important;
}
.sticky-footer {
  position: sticky;
  left: 0;
  padding-left: 28px;
  bottom: 0px;
  background-color: var(--component-background);
  height: 60px;
  width: 100%;
}
.sticky-footer-left {
  position: sticky;
  left: 0;
  padding-left: 32px;
  bottom: 0px;
  background-color: var(--component-background);
  width: 100%;
}

.modal-form-content {
  padding: 32px 72px 8px 72px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.page_wrapper {
  padding: 20px !important;
}
