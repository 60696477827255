.roleWrapper {
  // height: calc(100vh - 112px);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  .formWrapper {
    width: 100%;
  }

  .prompt {
    font-weight: 400;
    color: var(--mx-text-desc-color);
    font-size: 12px;
    margin-top: 8px;
  }
}

.permissionsWrapper {
  // height: calc(100vh - 112px);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  .desc {
    flex-shrink: 0;
    flex-grow: 0;
    width: 1000px;
    height: 43px;
    background-color: var(--border-color-split);
    align-items: center;
    margin-bottom: 30px;
  }

  .treeContainer {
    width: 1000px;
    height: 540px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    > h3 {
      width: 100%;
      padding: 10px 0 20px 10px;
    }
  }

  .treeWrapper {
    width: 100%;
  }
  :global {
    .mgmt-tree {
      background: var(--mx-module-bg-color);
    }
    .mgmt-tree-status-button-disabled {
      border-color: var(--mx-btn-default-border-color);
    }

    .mgmt-tree-status-button-checked {
      background: var(--mx-primary-color);
      color: var(--mx-btn-primary-color);
      border-color: var(--mx-light-border-color);
    }
    .ant-menu-item:not(.ant-menu-item-selected):hover,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
    .mgmt-tree-menu .ant-menu-submenu-title:hover {
      background-color: var(--mx-tree-hover-bg-color);
    }

    .ant-menu-submenu-selected.mgmt-tree-sub-menu-selected > .ant-menu-submenu-title,
    .mgmt-tree-menu .ant-menu-item-selected {
      color: var(--mx-btn-primary-color) !important;
    }

    .mgmt-tree-menu .ant-menu-item-selected {
      background: var(--mx-primary-color) !important;
    }

    .mgmt-tree-menu .ant-menu-submenu-selected.mgmt-tree-sub-menu-selected .ant-menu-submenu-title:hover {
      background: var(--mx-primary-color) !important;
      color: var(--mx-btn-primary-color) !important;
    }
    .ant-menu-submenu-selected:not(.mgmt-tree-sub-menu-selected) > .ant-menu-submenu-title,
    .ant-menu .ant-menu-submenu-selected:not(.mgmt-tree-sub-menu-selected) > .ant-menu-submenu-title {
      color: var(--mx-link-active-color);
    }
  }

  .miniTreeWrapper {
    width: 800px;
    :global {
      .mgmt-tree-node.mgmt-tree-node-third {
        .ant-checkbox-wrapper {
          .ant-checkbox {
            display: none;
          }
        }
      }
    }
  }
}

.buttonContainer {
  width: 1000px;
  position: sticky;
  background: var(--component-background);
  height: 60px;
  bottom: 0;
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

.toolTips_P1 {
  color: var(--primary-color);
  padding: 0;
  margin: 0;
}

.toolTips_P2 {
  color: white;
  padding: 0;
  margin: 0;
}
