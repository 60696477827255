.tips {
  margin-top: 5px;
  margin-bottom: 20px;
  white-space: nowrap;

  :global {
    .anticon {
      color: #ffb417;
      margin-right: 5px;
    }
  }
}

.modalContentSty {
  margin: auto;
}

.footerSty {
  text-align: end;
  margin-top: 12px;
}

.wrapperPadding {
  padding: 20px;
}
