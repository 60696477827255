$primary-color: var(--primary-color);
$bg-color: var(--item-hover-bg);

.wrapper {
  height: calc(100vh - 112px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .formWrapper {
    width: 100%;
  }

  .content {
    width: 1040px;
    margin: 20px auto;
    .formItem {
      display: flex;
      .label {
        width: 160px;
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 32px;
        color: rgba(var(--base-text-color), 217);
        font-size: 14px;
        &:after {
          content: ':';
          position: relative;
          top: -0.5px;
          margin: 0 8px 0 2px;
        }
      }
    }
  }

  .prompt {
    svg {
      color: var(--primary-color);
    }
    font-weight: 400;
    color: var(--mx-text-desc-color);
    font-size: 12px;
  }

  :global {
    .ant-form-item {
      margin-top: 54px;
    }
    .ant-tree {
      background: transparent;
    }
  }
}

.treeWrap {
  padding: 10px;
  width: 540px;
  height: 340px;
  background: var(--border-color-split);
  border-radius: 3px;
  border: 1px solid var(--border-color-split);
  overflow-y: auto;
  overflow-x: auto;
  :global {
    .ant-spin-nested-loading {
      height: 100%;
    }
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0 -8px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }

  li {
    float: left;
    width: 240px;
    background: $bg-color;
    border-radius: 2px;
    border: 1px solid $primary-color;
    padding: 5px 12px;
    margin: 0 12px 30px;
    height: 90px;
    overflow: hidden;

    dl {
      display: flex;
      align-items: center;
      margin: 0 0 5px 0;
      dt {
        position: relative;
        font-weight: normal;
        color: var(--heading-color);
        width: 72px;
        text-align: right;
        &:after {
          content: ':';
          position: relative;
          top: -0.5px;
          margin: 0 8px 0 2px;
        }
      }
      dd {
        margin: 0;
      }
    }

    .bottom {
      margin-bottom: 0;
      dd {
        color: #da2216;
      }
    }
  }
}

.desc {
  margin: 30px 0 -30px;
  max-width: 700px;
  :global {
    .ant-descriptions-item-content {
      color: #d22900;
    }
  }
}

.transfer {
  :global {
    .ant-transfer-list {
      width: 400px;
      height: 320px;
    }
  }
}
