.content {
  min-height: calc(100vh - 116px) !important;
}
$primary-color: var(--primary-color);
.wrapper {
  background: #f0f2f5 !important;
  padding: 0 !important;
}
.background {
  background-color: #fff;
  padding: 20px;
  margin: 10px 0;
  .code {
    margin: 0;
  }
  .company {
    display: inline-block;
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
    font-weight: 400;
  }
  :global {
    .ant-tabs-ink-bar {
      background: $primary-color;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab:hover {
      color: $primary-color;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border: 0;
    }
    .ant-row {
      align-items: center;
    }
    .ant-tree .ant-tree-node-content-wrapper {
      white-space: nowrap;
    }
    .ant-tree-list-holder {
      padding-bottom: 20px;
    }
  }
}

.treeBox {
  :global {
    .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
      top: 4px !important;
    }
    .ant-tree-switcher-leaf-line:after {
      left: -2px;
      top: 4px;
    }
  }
}

.titleFormatCode {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  margin-left: 10px;
}

.titleFormatIcon {
  margin-left: 20px;
  :global {
    .anticon {
      color: $primary-color;
    }
  }
}

.box {
  display: flex;
  align-items: flex-end;
}

.gray {
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 10px 0px;
  :global {
    .anticon {
      color: $primary-color;
      margin-right: 5px;
    }
  }
}

.restLogo {
  color: $primary-color;
  display: block;
  margin-top: -25px;
  cursor: pointer;
}

.red {
  color: #f5222d;
}

.hint {
  color: rgba(0, 0, 0, 0.5);
  margin: 5px 0 0 78px;
}

.icon {
  width: 24px;
  height: 24px;
  background: rgba(0, 159, 168, 0.15);
  border-radius: 20px;
  color: $primary-color;
  line-height: 28px;
  margin-right: 8px;
}

.mark {
  color: $primary-color;
  margin: 0 8px;
}

.col {
  border-left: 1px solid #d9d9d9;
}

.primaryBtn,
.primaryBtn:hover,
.primaryBtn:focus {
  background: linear-gradient(180deg, $primary-color 0%, $primary-color 100%);
  border-color: $primary-color;
}
.mgb {
  padding-top: 10px;
  margin-bottom: 15px;
}
.hasSelectBox {
  padding-top: 10px;
  .hasSelectNum {
    color: red;
    padding: 0 4px;
  }
}
