.accountBox {
  font-size: 12px;
  // color: rgba(0, 0, 0, 0.65);
  padding-top: 10px;
  :global {
    .ant-btn {
      padding-right: 5px;
      padding-left: 5px;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.account {
  min-height: 32px;
  display: flex;
  align-items: center;
  padding-right: 5px;
}
.used {
  color: red;
}
