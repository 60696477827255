$primary-color: var(--primary-color);

.restLogo {
    color: $primary-color;
    display: block;
    margin-top: -25px;
    cursor: pointer;
}

.pText {
    width: 104px;
    height: 104px;

    p {
      border: 1px dashed rgba(0, 0, 0, 0.15);
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 24%;
      font-size: 13px;
      font-weight: 400;
      padding-top: 58px;
      cursor: pointer;
    }

    // :hover {
    //   border: 1px dashed $primary-color;
    // }
  }

  .iconUpload{
    width: 110px;
  }
  .iconTip{
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 10px;
    width: calc(100% - 116px);
  }

  .example {
    padding-top: 30px;
    align-items: flex-start;
    display: flex;

    :global {
      span {
        color: #aaa;
      }

      img {
        width: 255px;
        height: 68px;
        margin-left: 20px;
        margin-bottom: 12px;
      }
    }
  }

  .previewModal {
    width: 1040px;
    height: 585px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  
    .previewImg {
      width: 1040px;
      // height: 240px;
      // object-fit: scale-down;
    }
  
    .topMenu {
      width: 1040px;
    }
  }