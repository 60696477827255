$prefixCls: mgmt-steps;

.#{$prefixCls}-wrapper {
  width: 100%;
  height: 110px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .ant-steps-item-title::after {
    background: var(--disabled-color) !important;
  }
}
